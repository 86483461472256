<template>
  <page-header-wrapper>
    <div class="app-container home" >
      <a-card :bordered="false">
        <a-tabs default-active-key="1" tab-position="left">
          <a-tab-pane key="1" :tab="$t('本地邮件')">
            <local></local>
          </a-tab-pane>
          <a-tab-pane key="2" :tab="$t('阿里邮件')">
            <aliyun></aliyun>
          </a-tab-pane>
          <a-tab-pane key="3" :tab="$t('腾讯邮件')">
            <tencent></tencent>
          </a-tab-pane>
        </a-tabs>

      </a-card>
    </div>
  </page-header-wrapper>
</template>
<style scoped  lang="less">
.home {
  .row-class{
    padding: 10px;
  }
  .app-container-card{
    margin-top: 10em;
    .bottom {
      margin-top: 13px;
      line-height: 12px;
      text-align: center;
    }
    .content{
      font-size: 12px;
      text-align: center;
    }
  }
}
.sms-account-page {
  .ant-statistic-content {
    color: #0081ff;
    text-align: center;
    font-weight: 400;
  }
}
.spin-load {
  text-align: center;
}

</style>

<script>
import aliyun from './aliyun.vue'
import local from './local.vue'
import tencent from './tencent.vue'
export default {
  name: '',
  components: {
    aliyun,
    local,
    tencent
  },
  data() {
    return {

    }
  },
  mounted() {},
  methods: {

  }
}
</script>
